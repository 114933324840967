import { Link, graphql } from 'gatsby'
import AppBorder from '../components/AppBorder'
import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/Seo'
import styled from 'styled-components'

const Projects = styled.div`
  border-bottom: 2px dotted black;
  margin-bottom: 1em;
  color: black;
  opacity: 0.6;
  transition: .5s all;
  -webkit-backface-visibility: hidden; /* Fix text jumping */

  &:hover {
    opacity: 1;
  }

  &:last-child {
    border: 0;
  }

  a {
    color: #b58900;
    border-bottom: 1px dotted #b58900;
  }
`

const ProjectIndex = ({ data }) => {
  const { edges: projects } = data.allMdx

  return (
    <Layout>
      <SEO title='Projects - Daniel Swaine' />
      <AppBorder title='Projects' colour='#fff'>
        {projects.map(({ node: project }) =>
          <Projects key={project.id}>
            <h2>{project.frontmatter.title}</h2>
            <p>
              {project.excerpt}{' '}
              <Link to={project.fields.slug}>continue reading</Link>.
            </p>
          </Projects>
        )}
      </AppBorder>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ProjectIndex
